<script lang="ts" setup>
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import { Link } from '@inertiajs/vue3'

import { FeaturedCity } from '@/Components/featured-cities-slider/interfaces/FeaturedCity'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

defineProps<{
    cities: FeaturedCity[]
}>()

const swiper = ref()

</script>

<template>
    <div id="vue-featured-slider" class="swiper-container featured-slider pb-4 pb-lg-5">
        <Swiper
            class="pb-5"
            :modules="[Navigation, Pagination]"
            :space-between="16"
            :pagination="{ clickable: true, dynamicBullets: true }"
            :navigation="true"
            slides-per-view="auto"
            centered-slides
            centered-slides-bounds
            grab-cursor
            lazy
            @swiper="swiper = $event"
        >
            <SwiperSlide v-for="(city, index) in cities" :key="`city_${ index }`">
                <div class="card card-poster dark-overlay hover-animate mb-4 mb-lg-0 position-relative">
                    <div class="position-absolute top-0 left-0 z-index-10 p-3">
                        <div class="pre-title bg-primary py-1 px-2 cl-light rounded-sm mb-3">
                            {{ `from ${ city.priceFrom }` }}
                        </div>
                    </div>

                    <Link :href="city.url" class="tile-link" />

                    <img
                        :src="city.thumbnail"
                        class="swiper-lazy bg-image h-100"
                        :alt="`${ city.name } Hostels from ${ city.priceFrom }`"
                    >

                    <div class="featured-slider-body card-body overlay-content text-center w-100 ">
                        <h6 class="card-title text-shadow text-uppercase text-white">
                            {{ city.name }}
                        </h6>
                        <p class="card-text text-sm" />
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<style lang="scss" scoped>
.swiper {
    overflow: unset;
}

.swiper-container {
    overflow: hidden;
}

.swiper-pagination {
    width: 120px;
}

.featured-slider .swiper-slide {
    width: 18%;
}

.featured-slider-body {
    padding: 0.5rem;

    @media (min-width: 576px) {
        padding: 1.25rem;
    }
}

@media (max-width: 991px) {
    .featured-slider .swiper-slide {
        width: 40%;
    }
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -24px;
}

.swiper-pagination .swiper-pagination-bullet {
    opacity: 1;
    background: #fff;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: #FF5852;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: none;
}

@media (max-width: 991px) {
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
}

.swiper-button-prev::after,
.swiper-button-next::after {
    content: '';
}

.card-poster {
    min-height: 310px;
}
</style>